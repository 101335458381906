const filteredComponents = [
  "Site_Message",
  "Disclaimer",
  "Navigation",
  "Transaction_Module",
  "Reference",
  "Embedded_Media",
  "SiteMap",
  "Person_Profile",
  "CAT_Van_Locations",
  "External_Asset",
  "Query_Builder"
]

export const getComponent = (component) => {
  return component.segments[0].segmentItemDetails[0].parameterItemDetails[0].dcr
}

export const getFirst150Chars = (inputString) => {
  const stripHtmlTagsFromString = (htmlString) =>
    htmlString.replace(/<[^>]*>?/gm, "")

  let trimmedString = stripHtmlTagsFromString(inputString)

  if (trimmedString.length > 150) {
    trimmedString = `${trimmedString.slice(0, 150)} ...`
  }
  return trimmedString
}

const flattenObject = (obj, flattened = {}) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key], flattened))
    } else {
      if (flattened[key]) {
        flattened[key] += `#${obj[key]}`
      } else {
        flattened[key] = obj[key]
      }
    }
  })
  return flattened
}

const filterComponents = (component) => {
  const componentDcr = getComponent(component)
  if (filteredComponents.includes(component.name) || !componentDcr) {
    return ""
  } else {
    let content = flattenObject(componentDcr)

    return content
  }
}

const filterPageContent = (page) => {
  const content = page.content.components.map((component) =>
    filterComponents(component)
  )

  return content
}

export default filterPageContent
