import React from "react"
import { graphql } from "gatsby"
import BaseLayout from "../templates/baseLayout"
import filterPageContent, {
  getComponent,
  getFirst150Chars
} from "../utilities/filterPageContent"
import getSitewideMessages from "../utilities/getSitewideMessages"
import { v4 as uuidv4 } from "uuid"
import { ClientSearch } from "trv-ebus-tcom-reactcomponents"

export const query = graphql`
  query {
    allEcsPage {
      nodes {
        slug
        content
        id
      }
    }

    ecsSitewideMessages {
      content {
        messageType
        siteMessageEnabled
        siteMessages {
          messageLocation
          shortCopy
        }
      }
    }
  }
`

export default function SearchResults({ data, location }) {
  const sitewideMessages = getSitewideMessages(
    data.ecsSitewideMessages.content,
    location.pathname
  )

  const headData = {
    // pageInformation: components["Page_Information"],
    // tagman: components["Tagman"],
    htmlAttributes: { lang: "en-us" },
    canonicalUrl: location.href
  }

  let searchIndex = []
  let allPdfData = {}
  const filterPageData = data.allEcsPage.nodes.filter(node => !node.slug.includes("/thankyou"))  
  const pageData = filterPageData.map((page) => {
    const content = filterPageContent(page)

    var finalContent = content
      .filter((value) => Object.keys(value).length !== 0)
      .reduce(function (acc, x) {
        for (var key in x) acc[key] = x[key]
        return acc
      }, {})

    const {
      contentRoute,
      disclaimerText,
      applicationName,
      acceptLanguage,
      btnStyle,
      contentAlignment,
      desktopHeroType,
      imageWrapped,
      linkTarget,
      mobileImageSize,
      linkType,
      layout,
      modelName,
      name,
      pageType,
      pdfText,
      textStyle,
      type,
      useHeroLink,
      hrefLangID,
      langURL,
      imageMobileSrc,
      imageSrc,
      imageAlt,
      pdfHref: pdf,
      ...finalContentforSearch
    } = finalContent

    const { linkHref: link, href: href } = finalContent
    const allDocumentLinks = (pdf || "")
      .concat(link ? "#" + link : "")
      .concat(href ? "#" + href : "")

    if (allDocumentLinks) {
      allDocumentLinks.split("#").forEach((link) => {
        if (/.(?:pdf|doc|docx|ppt|pptx)$/.test(link)) {
          const decodedFilename = decodeURI(link.split("/").slice(-1)[0])
          allPdfData[decodedFilename] = {
            id: uuidv4(),
            slug: link,
            href: link,
            title: decodedFilename,
            description: decodedFilename
          }
        }
      })
    }

    const pageInformation =
      getComponent(
        page.content.components.find(
          (component) => component.name == "Page_Information"
        )
      ) ?? {}

    return {
      id: page.id,
      slug: `/${page.slug}`,
      title: pageInformation.pageTitle ?? "No title",
      description: getFirst150Chars(
        pageInformation.pageDescription ?? "No description"
      ),
      content: JSON.stringify(Object.values(finalContentforSearch)).replace(
        /<[^>]*>?/gm,
        ""
      )
    }
  })

  searchIndex = [
    ...Object.keys(allPdfData).map((key) => allPdfData[key]),
    ...pageData
  ]

  const noResultsLink = [
    {
      title: "Find a General Agent",
      href: "/find-agent"
    },
    {
      title: "Report a Claim",
      href: "/claim-reporting"
    },
    {
      title: "Contact Us",
      href: "/contact-us"
    },
    {
      title: "NorthfieldOnline",
      href: "https://signin.travelers.com/"
    }
  ]

  return (
    <BaseLayout head={headData} sitewideMessages={sitewideMessages}>
      <section id="content-link" role="main" tabIndex="-1">
        <ClientSearch data={searchIndex} noResultsLink={noResultsLink} />
      </section>
    </BaseLayout>
  )
}
