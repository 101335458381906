export const CompanyNames = [
  {
    name: "Northfield Insurance Company",
    value: "Northfield Insurance Company"
  },
  {
    name: "Northland Insurance Company",
    value: "Northland Insurance Company"
  }
]
