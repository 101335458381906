export const Relationships = [
  {
    name: "Northfield Policyholder or Insured",
    value: "Northfield Policyholder or Insured"
  },
  {
    name: "Individual who sustained damage or injury",
    value: "Individual who sustained damage or injury"
  },
  { name: "Witness", value: "Witness" },
  { name: "Other", value: "Other" }
]
