export const Empty_FirstName = "We need your first name."
export const Empty_LastName = "We need your last name."
export const Empty_Organization = "We need your organization."
export const Empty_Phone = "We need your phone number."
export const Empty_Email = "We need your email."
export const WrongFormat_Email =
  "We need your email address in the format: yourname@example.com."
export const Empty_Message = "We need your message."
export const Empty_State = "We need your state."
export const Empty_Relationship = "We need your relationship."
export const Empty_InsuredName = "We need the insured name."
export const Empty_InsuredPhone = "We need the insured phone number."
export const Empty_DateOfLoss = "We need the date of loss."
export const Empty_DescriptionOfLoss = "We need the description of loss."
export const Empty_StateWhereLossOccurred =
  "We need the state where loss occurred."
export const Empty_InsuredPolicyNumber = "We need the insured policy number."
export const Empty_EmailClaimTo =
  "We need the email to send a copy of this claim."
export const Empty_CompanyName = "We need the issuing insurance company name."
export const Empty_GeneralAgentName =
  "We need the name of the general agent or program administrator."
export const Invalid_GeneralAgentName =
  "Please enter a valid name of the general agent or program administrator."
export const Empty_City = "We need the city."
export const Invalid_City = "Please enter a valid city."
export const Empty_State_incentive = "We need the state."
export const Empty_AgencyName = "We need the Travelers agency name."
export const Invalid_AgencyName = "Please enter a valid Travelers agency name."
export const Empty_AgencyCode = "We need the Travelers agency code."
export const Invalid_AgencyCode = "We need a valid travelers agency code."
export const Empty_Phone_incentive = "We need the phone number."
export const Empty_AgencyCity = "We need the agency city."
export const Invalid_AgencyCity = "Please enter a valid agency city."
export const Empty_AgencyState = "We need the agency state."
export const Empty_ContactName = "We need the contact name."
export const Invalid_ContactName = "Please enter a valid contact name."
export const Empty_AccountName = "We need the account name."
export const Invalid_AccountName = "Please enter a valid account name."
export const Empty_GrossWrittenPremium = "We need the gross written premium."
export const Invalid_GrossWrittenPremium =
  "Please enter a valid gross written premium."
export const Empty_PolicyNumber = "We need the policy number."
export const Invalid_PolicyNumber = "Please enter a valid policy number."
export const Empty_PolicyEffectiveDate = "We need the policy effective date."
export const Range_PolicyEffectiveDate =
  "Please enter a date in the current calender year only."
export const Invalid_PolicyEffectiveDate =
  "We need a valid policy effective date."
export const Invalid_DateOfLoss = "We need a valid date of loss."
export const Range_DateOfLoss = "Please enter a date that is not in the future."
export const Invalid_Phone = "We need a valid phone number."
export const Empty_State_LossOccurred = "We need the state where loss occurred."
export const Service_Error =
  "We have encountered a technical error, please wait a few minutes and try again."
export const Invalid_Email = "We need  a valid email address."
